import React from 'react';
import IconsUI, { ui } from '../components/IconsUI';

function IconsExample() {
  // const m = parseInt(sessionStorage.getItem('@App:m'));
  // if (m !== 1) window.location.href = '/500';

  return (
    <div className='wrappedefpage'>
      {/* trazer todos os possíveis icones */}

      <h4>Icons</h4>

      <div className='icons'>
        <IconsUI
          info={ui.social.telegram}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.telegram`}
        />

        <IconsUI
          info={ui.social.whatsapp}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.whatsapp`}
        />
        <IconsUI
          info={ui.social.email}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.email`}
        />
        <IconsUI
          info={ui.social.calendar.add}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.calendar.add`}
        />
        <IconsUI
          info={ui.social.calendar.reg}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.calendar.reg`}
        />
        <IconsUI
          info={ui.social.phone.color}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.phone.color`}
        />
        <IconsUI
          info={ui.social.phone.reg}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.phone.reg`}
        />

        <IconsUI
          info={ui.social.message}
          clsnm='m-3 hov-green'
          tooltip={`ui.social.message`}
        />

        <IconsUI
          info={ui.action.back}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.back`}
        />

        <IconsUI
          info={ui.action.edit}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.edit`}
        />
        <IconsUI
          info={ui.action.delete.trash}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.delete.trash`}
        />
        <IconsUI
          info={ui.action.delete.circle}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.delete.circle`}
        />
        <IconsUI
          info={ui.action.add.reg}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.add.reg`}
        />
        <IconsUI
          info={ui.action.add.color}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.add.color`}
        />
        <IconsUI
          info={ui.action.add.circle}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.add.circle`}
        />

        <IconsUI
          info={ui.action.expand}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.expand`}
        />
        <IconsUI
          info={ui.action.search}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.search`}
        />
        <IconsUI
          info={ui.action.magnifier}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.magnifier`}
        />

        <IconsUI
          info={ui.action.upload.color}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.upload.color`}
        />
        <IconsUI
          info={ui.action.upload.reg}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.upload.reg`}
        />
        <IconsUI
          info={ui.action.download}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.download`}
        />
        <IconsUI
          info={ui.action.start}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.start`}
        />
        <IconsUI
          info={ui.action.login}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.login`}
        />

        <IconsUI
          info={ui.action.logout}
          clsnm='m-3 hov-green'
          tooltip={`ui.action.logout`}
        />

        <IconsUI
          info={ui.folder.open}
          clsnm='m-3 hov-green'
          tooltip={`ui.folder.open`}
        />

        <IconsUI
          info={ui.file.pdf}
          clsnm='m-3 hov-green'
          tooltip={`ui.file.pdf`}
        />
        <IconsUI
          info={ui.file.image}
          clsnm='m-3 hov-green'
          tooltip={`ui.file.image`}
        />
        <IconsUI
          info={ui.file.other}
          clsnm='m-3 hov-green'
          tooltip={`ui.file.other`}
        />
        <IconsUI
          info={ui.file.attach}
          clsnm='m-3 hov-green'
          tooltip={`ui.file.attach`}
        />

        <IconsUI
          info={ui.etapas.simulacao}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.simulacao`}
        />
        <IconsUI
          info={ui.etapas.documentacao_comprador}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.documentacao_comprador`}
        />
        <IconsUI
          info={ui.etapas.aprovacao_credito}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.aprovacao_credito`}
        />
        <IconsUI
          info={ui.etapas.documentacao_vendedor}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.documentacao_vendedor`}
        />
        <IconsUI
          info={ui.etapas.documentacao_imovel}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.documentacao_imovel`}
        />
        <IconsUI
          info={ui.etapas.avaliacao_engenharia}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.avaliacao_engenharia`}
        />
        <IconsUI
          info={ui.etapas.confirmacao_valores}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.confirmacao_valores`}
        />
        <IconsUI
          info={ui.etapas.assinatura_formularios}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.assinatura_formularios`}
        />
        <IconsUI
          info={ui.etapas.analise_juridica}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.analise_juridica`}
        />
        <IconsUI
          info={ui.etapas.assinatura_contrato}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.assinatura_contrato`}
        />
        <IconsUI
          info={ui.etapas.prefeitura}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.prefeitura`}
        />
        <IconsUI
          info={ui.etapas.cartorio}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.cartorio`}
        />
        <IconsUI
          info={ui.etapas.finalizado}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.finalizado`}
        />
        <IconsUI
          info={ui.etapas.mindmap}
          clsnm='m-3 hov-green'
          tooltip={`ui.etapas.mindmap`}
        />

        <IconsUI
          info={ui.status.waiting}
          clsnm='m-3 hov-green'
          tooltip={`ui.status.waiting`}
        />
        <IconsUI
          info={ui.status.done}
          clsnm='m-3 hov-green'
          tooltip={`ui.status.done`}
        />

        <IconsUI
          info={ui.user.reg}
          clsnm='m-3 hov-green'
          tooltip={`ui.user.reg`}
        />
        <IconsUI
          info={ui.user.add}
          clsnm='m-3 hov-green'
          tooltip={`ui.user.add`}
        />
        <IconsUI
          info={ui.user.delete}
          clsnm='m-3 hov-green'
          tooltip={`ui.user.delete`}
        />

        <IconsUI
          info={ui.misc.cross}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.cross`}
        />
        <IconsUI
          info={ui.misc.check.color}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.check.color`}
        />
        <IconsUI
          info={ui.misc.check.circle}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.check.circle`}
        />
        <IconsUI
          info={ui.misc.clock}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.clock`}
        />
        <IconsUI
          info={ui.misc.add_database}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.add_database`}
        />
        <IconsUI
          info={ui.misc.edit}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.edit`}
        />
        <IconsUI
          info={ui.misc.tasks}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.tasks`}
        />

        <IconsUI
          info={ui.misc.star}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.star`}
        />

        <IconsUI
          info={ui.misc.starlight}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.starlight`}
        />

        <IconsUI
          info={ui.misc.house}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.house`}
        />

        <IconsUI
          info={ui.misc.money}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.money`}
        />

        <IconsUI
          info={ui.misc.menu}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.menu`}
        />

        <IconsUI
          info={ui.misc.config}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.config`}
        />

        <IconsUI
          info={ui.misc.logout}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.logout`}
        />

        <IconsUI
          info={ui.misc.products}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.products`}
        />

        <IconsUI
          info={ui.money.payment}
          clsnm='m-3 hov-green'
          tooltip={`ui.money.payment`}
        />

        <IconsUI
          info={ui.money.invoice}
          clsnm='m-3 hov-green'
          tooltip={`ui.money.invoice`}
        />
        <IconsUI
          info={ui.money.subscriber}
          clsnm='m-3 hov-green'
          tooltip={`ui.money.subscriber`}
        />
        <IconsUI
          info={ui.money.cart}
          clsnm='m-3 hov-green'
          tooltip={`ui.money.subscriber`}
        />

        <IconsUI
          info={ui.misc.dash}
          clsnm='m-3 hov-green'
          tooltip={`ui.misc.dash`}
        />
      </div>
    </div>
  );
}

export default IconsExample;
