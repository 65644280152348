import * as end from '../services/endpoints';

export default async function GetBlob(
  blobCode,
  blobTable,
  blobColumn,
  tenantCode,
  tenant,
  token,
) {
  try {
    const form = new FormData();
    let endpoint;
    form.append('codigo', blobCode);
    form.append('tenant', tenant);
    form.append('tenant_codigo', tenantCode);
    form.append('token', token);

    switch (blobTable) {
      case 'TENANTS':
        switch (blobColumn) {
          case 'LOGO':
            endpoint = end.tenants.tenant.read.logo;
            break;
          case 'TIMBRADO':
            endpoint = end.tenants.tenant.read.timbrado;
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    const url = `${end.OCI}${endpoint}/`;
    const response = await fetch(url, {
      method: 'POST',
      body: form,
    });

    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error(error);
  }
}
