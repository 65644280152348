import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormHeader from '../components/FormHeader';
import { IMaskInput } from 'react-imask';
import register from '../services/register';

function Register() {
  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const registry = async (event) => {
    event.preventDefault();

    try {
      const response = await register(cpf, nome, email, telefone, password);

      const data = response.data;
      const { message } = data;
      if (data.user_found) {
        setMessage(
          'Já existe um usuário com os dados fornecidos. Efetue login ou recupere a senha.',
        );
      } else {
        if (message === 'Criado') {
          setMessage('Usuário criado com sucesso! Você já pode fazer login.');
        } else {
        setMessage(message);
        }
      }
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className='wrapperform'>
      <header className='form-header text-center my-5'>
        <FormHeader formname={'Cadastre-se'} />
      </header>
      <div className='w60 mw600'>
        <form
          action=''
          className='w-100'
        >
          <Form.Label
            htmlFor='nome'
            className='input-label'
          >
            Nome
          </Form.Label>
          <Form.Control
            required
            type='text'
            id='nome'
            autoComplete='name'
            className='mb-2'
            onChange={({ target: { value } }) => setNome(value)}
          />

          <Form.Label
            htmlFor='telefone'
            className='input-label'
          >
            WhatsApp
          </Form.Label>
          <Form.Control
            required
            type='text'
            as={IMaskInput}
            mask='(00) 00000-0000'
            id='telefone'
            autoComplete='phone'
            className='mb-2'
            onChange={({ target: { value } }) => setTelefone(value)}
          />

          <Form.Label
            htmlFor='cpf'
            className='input-label'
          >
            CPF
          </Form.Label>
          <Form.Control
            required
            type='text'
            as={IMaskInput}
            mask='000.000.000-00'
            id='cpf'
            name='cpf'
            autoComplete='cpf'
            aria-describedby='cpf mask-cpf'
            onChange={({ target: { value } }) => setCpf(value)}
            className='mb-2'
          />

          <Form.Label
            htmlFor='email'
            className='input-label'
          >
            E-Mail
          </Form.Label>
          <Form.Control
            required
            type='text'
            id='email'
            name='email'
            autoComplete='email'
            aria-describedby='email'
            onChange={({ target: { value } }) => setEmail(value)}
            className='mb-2'
          />

          <Form.Label
            htmlFor='password'
            className='input-label'
          >
            Password
          </Form.Label>
          <Form.Control
            required
            type='password'
            id='password'
            className='mb-4'
            onChange={({ target: { value } }) => setPassword(value)}
          />

          <p className='my-5 text-center fc-red fw-500'>{message}</p>

          <div className='d-flex flex-row justify-content-around my-2'>
            <Link to='/login'>
              <Button
                className='no_under'
                variant='link'
              >
                Já tem cadastro? Faça login
              </Button>
            </Link>
            <Button
              variant='primary'
              onClick={(event) => registry(event)}
            >
              Registrar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Register;
