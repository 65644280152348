import { useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import GlobalContext from '../context/GlobalContext';
import IconsUI, { ui } from '../components/IconsUI';
import { data_br } from '../assets/js/helpers';

export default function User() {
  const { navigate, loading, checkLogin, store } = useContext(GlobalContext);
  const { user, email, cpf, telefone, assinantes } = store();

  const logged = checkLogin();
  useEffect(() => {
    if (!logged) navigate('/login');
  }, [logged, navigate]);

  const groupTenant =
    assinantes.length > 0 &&
    assinantes.reduce((acc, assinante) => {
      if (!acc[assinante.TENANT_TENANT]) {
        acc[assinante.TENANT_TENANT] = [];
      }
      acc[assinante.TENANT_TENANT].push(assinante);
      return acc;
    }, {});

  const tables = Object.keys(groupTenant).map((tenant, index) => {
    const tableAssinantes = groupTenant[tenant].map((assinante, index) => (
      <tr key={index}>
        <td>{assinante.PRODUCT_NOME}</td>
        <td>{assinante.PLANO_NOME}</td>
        <td>{assinante.FREQUENCIA_NOME}</td>
        <td>
          {assinante.FREQUENCIA_ID === 1 ? '-' : data_br(assinante.EXPIRATION)}
        </td>
        <td>{assinante.ROLE_DESCRIPTION}</td>
        <td>
          <IconsUI info={ui.action.edit} clsnm='fc-grey' />
        </td>
      </tr>
    ));

    return (
      <div key={index}>
        <h5 className='mt-4 fc-regular'>
          <a
            href={`/conta/${tenant}`}
            className='fc-link no-underline'
          >
            <IconsUI
              info={ui.misc.star}
              clsnm='me-1 fc-blue'
            />
            {tenant}
          </a>
        </h5>
        <table className='table table-striped table-hover'>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Plano</th>
              <th>Validade</th>
              <th>Vencimento</th>
              <th>Perfil</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{tableAssinantes}</tbody>
        </table>
      </div>
    );
  });

  // const tableAssinantes =
  //   assinantes.length > 0 &&
  //   assinantes.map((assinante, index) => (
  //     <tr key={index}>
  //       <td>{assinante.TENANT_TENANT}</td>
  //       <td>{assinante.PRODUCT_NOME}</td>
  //       <td>{assinante.PLANO_NOME}</td>
  //       <td>{assinante.FREQUENCIA_NOME}</td>
  //       <td>
  //         {assinante.FREQUENCIA_ID === 1 ? '' : data_br(assinante.EXPIRATION)}
  //       </td>
  //       <td>{assinante.ROLE_DESCRIPTION}</td>
  //       <td>
  //         <IconsUI info={ui.action.edit} />
  //       </td>
  //     </tr>
  //   ));

  // const table = (
  //   <table className='table table-striped table-hover'>
  //     <thead>
  //       <tr>
  //         <th>Conta</th>
  //         <th>Produto</th>
  //         <th>Plano</th>
  //         <th>Validade</th>
  //         <th>Vencimento</th>
  //         <th>Perfil</th>
  //         <th></th>
  //       </tr>
  //     </thead>
  //     <tbody>{tableAssinantes}</tbody>
  //   </table>
  // );

  if (loading) return <LoadingFull />;

  return (
    <>
      <NavBar logged={logged} />
      <main className='container'>
        <h4>Meu Cadastro</h4>
        <p className='mt-2'>Olá, {user}!</p>

        <h5 className='mt-4'>Dados Pessoais</h5>
        <p className='mt-3'>
          <span>Nome</span>: {user}
          <br />
          <span>CPF</span>: {cpf}
        </p>

        <h5 className='mt-4'>Contato</h5>
        <p className='mt-3'>
          <span>Email</span>: {email}
          <br />
          <span>Telefone</span>: {telefone}
        </p>

        <h5 className='mt-4 softxt fw500'>Minhas Contas</h5>
        {assinantes.length > 0 && tables}
      </main>
    </>
  );
}
