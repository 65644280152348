import React from 'react';
import MasterProvider from '../context/MasterProvider';
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import RenderSection from '../components/master/RenderSection';

export default function Master() {

  return (
    <MasterProvider>
      <NavBar />
      <main className='main-container'>
        <div className='d-flex flex-row'>
          <section className='main-container-sidebar'>
            <SideBar />
          </section>
          <section className='main-container-render'>
            <RenderSection />
          </section>
        </div>
      </main>
    </MasterProvider>
  );
}
