import { requestPost } from './requestPost';
import { auth } from '../config/endpoints';

export default async function reset(email, cpf, password, otp) {
  const response = await requestPost(auth.reset, {
    email,
    cpf,
    password,
    otp,
  });

  return response;
}
