import React, { useCallback, useState } from 'react';
import BadgeOnOff from '../BadgeOnOff';

import * as end from '../../config/endpoints';
import { post } from '../../services/requests';
import Loading from '../Loading';

export default function TenantModalUsersLine({
  tu,
  index,
  handleGetTenantsUsers,
}) {
  const [loadingOnOffUser, setLoadingOnOffUser] = useState(false);
  const [loadingOnOffTenantUser, setLoadingOnOffTenantUser] = useState(false);

  const handleUpdateTenantUserActive = useCallback(
    async (tu) => {
      setLoadingOnOffTenantUser(true);
      const data = await post(end.tenants.users.update.active, {
        tenant_codigo: tu.tenant_codigo,
        user_codigo: tu.user_codigo,
        active: !tu.tenant_user_active,
      });

      if (data.status === 200) {
        await handleGetTenantsUsers();
      }
      setLoadingOnOffTenantUser(false);
    },
    [handleGetTenantsUsers],
  );

  const handleUpdateUserActive = useCallback(
    async (tu) => {
      setLoadingOnOffUser(true);
      const data = await post(end.user.update.active, {
        tenant_codigo: tu.tenant_codigo,
        user_codigo: tu.user_codigo,
        active: !tu.user_active,
      });

      if (data.status === 200) {
        await handleGetTenantsUsers();
      }
      setLoadingOnOffUser(false);
    },
    [handleGetTenantsUsers],
  );

  return (
    <tr key={index}>
      <td>{tu.user_nome}</td>
      <td>{tu.user_email}</td>
      <td>{tu.user_telefone}</td>
      <td>
        {loadingOnOffUser ? (
          <Loading
            class_op='loading'
            sm={true}
          />
        ) : (
          <BadgeOnOff
            clsnm='hov-pointer badgeonoff'
            on={tu.user_active}
            click={() => {
              handleUpdateUserActive(tu);
            }}
          />
        )}
      </td>
      <td>{tu.role}</td>
      <td>
        {loadingOnOffTenantUser ? (
          <Loading
            class_op='loading'
            sm={true}
          />
        ) : (
          <BadgeOnOff
            clsnm='hov-pointer badgeonoff'
            on={tu.tenant_user_active}
            click={() => {
              handleUpdateTenantUserActive(tu);
            }}
          />
        )}
      </td>
      <td>{tu.user_expiration ? tu.user_expiration : 'N/A'}</td>
      <td><BadgeOnOff
            clsnm='hov-pointer badgeonoff'
            on={!tu.expired}
          /></td>
    </tr>
  );
}
