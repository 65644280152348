import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import { data_br, datahora } from '../../assets/js/helpers';
import IconsUI, { ui } from '../IconsUI';
import TenantModal from './TenantModal';

export default function TenantsLine({ d, index }) {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <tr key={index}>
      <th scope='row'>{d.id}</th>
      <td>{d.tenant}</td>
      <td>{d.email}</td>
      <td>{d.codigo}</td>
      <td>{d.active}</td>
      <td>{data_br(d.created_at)}</td>
      <td>{datahora(d.updated_at)}</td>
      <td>
        <IconsUI
          info={ui.misc.edit}
          clsnm='hov-green'
          click={handleShowModal}
        />
      </td>
      <>
        <Modal
          show={showModal}
          fullscreen={true}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title className=''>
              <div className='d-flex flex-row justify-content-between'>
                <span>
                  Tenant<span className='mx-2'>|</span>
                  <span className=''>{d.tenant}</span>
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TenantModal
              obj={d}
              on={d.active}
            />
          </Modal.Body>
          <Modal.Footer>          
          </Modal.Footer>
        </Modal>
      </>
    </tr>
  );
}
