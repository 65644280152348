import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormHeader from '../components/FormHeader';
import { IMaskInput } from 'react-imask';
import recover from '../services/recover';
import Loading from '../components/Loading';

export default function Recover() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const recovery = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await recover(email, cpf);
      console.log(response);
      const data = response.data;
      setMessage(data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <div className='wrapperform'>
      <header className='form-header text-center my-5'>
        <FormHeader formname={'Recupere seu acesso'} />
      </header>
      <div className='w60 mw600'>
        <form
          action=''
          className='w-100'
          autoComplete='on'
        >
          <Form.Label
            htmlFor='cpf'
            className='input-label'
          >
            CPF
          </Form.Label>
          <Form.Control
            required
            type='text'
            as={IMaskInput}
            mask='000.000.000-00'
            id='cpf'
            name='cpf'
            autoComplete='cpf'
            aria-describedby='cpf mask-cpf'
            onChange={({ target: { value } }) => setCpf(value)}
            className='mb-2'
          />

          <Form.Label
            htmlFor='email'
            className='input-label'
          >
            E-Mail
          </Form.Label>
          <Form.Control
            required
            type='text'
            id='email'
            name='email'
            autoComplete='email'
            aria-describedby='email'
            onChange={({ target: { value } }) => setEmail(value)}
            className='mb-2'
          />

          <p className='my-5 text-center fc-red fw-500'>{message}</p>

          {loading ? (
            <div className='d-flex flex-row justify-content-center'>
              <Loading class_op='mt-5' />
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center  justify-content-around my-2'>
              <Button
                className='mb-2'
                variant='primary'
                onClick={(event) => recovery(event)}
              >
                Solicitar código de recuperação
              </Button>
              <Link to='/reset'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Já tem o código OTP? Clique aqui.
                </Button>
              </Link>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
