import React, { useContext } from 'react';
import MasterContext from '../../context/MasterContext';
import BadgeOnOff from '../BadgeOnOff';
import Loading from '../Loading';

import IconsUI, { ui } from '../IconsUI';

import { currFormat } from '../../assets/js/helpers';

export default function Driller() {
  const { loading, planosProdutos, message } = useContext(MasterContext);

  if (loading) return <Loading class_op='loading' />;

  return (
    <div className=''>
      <span className='mb-5'>{message}</span>
      <h4>Planos e Produtos</h4>

      <table className='table table-striped table-hover text-center f09 table-vertical-center'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Produto</th>
            <th scope='col'>Plano</th>
            <th scope='col'>Assinantes</th>
            <th scope='col'>Frequencia</th>
            <th scope='col'>Descrição</th>
            <th scope='col'>Trial</th>
            <th scope='col'>Valor</th>
            <th scope='col'>Status</th>
            <th scope='col'></th>
          </tr>
        </thead>
        <tbody>
          {planosProdutos &&
            planosProdutos.map((d, index) => (
              <tr key={index}>
                <th scope='row'>{d.id}</th>
                <td>{d.product_nome}</td>
                <td>{d.plano_nome}</td>
                <td>{d.total_assinantes_ativos}</td>
                <td>{d.frequencia_nome}</td>
                <td>{d.product_descricao}</td>
                <td>{d.plano_trial}</td>
                <td>{currFormat(d.plano_valor)}</td>
                <td className='f08'>
                  <BadgeOnOff on={d.plano_active} />
                </td>
                <td>
                  <IconsUI info={ui.misc.edit} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
