import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import MasterContext from '../../context/MasterContext';
import IconsUI, { ui } from '../IconsUI';

import Loading from '../Loading';

import { currFormat } from '../../assets/js/helpers';
import * as end from '../../config/endpoints';
import { post } from '../../services/requests';
import TenantModalAssinantesLine from './TenantModalAssinantesLine';
import TenantModalUsersLine from './TenantModalUsersLine';

function TenantModal({ obj, on }) {
  const {
    users,
    roles,
    tenantUsers,
    setTenantUsers,
    planosProdutos,
    // setPlanosProdutos,
    assinantes,
    setAssinantes,
  } = useContext(MasterContext);

  const [loading, setLoading] = useState(false);

  const [addRole, setAddRole] = useState('');
  const [addUser, setAddUser] = useState('');

  const [addPlano, setAddPlano] = useState('');

  // const [loadingOnOffUser, setLoadingOnOffUser] = useState(false);
  // const [loadingOnOffTenantUser, setLoadingOnOffTenantUser] = useState(false);

  const [thisTenantUsers, setThisTenantUsers] = useState([]);
  const [thisAssinantes, setThisAssinantes] = useState([]);

  useEffect(() => {
    let thisUsers = tenantUsers.filter((tu) => tu.tenant_codigo === obj.codigo);
    setThisTenantUsers(thisUsers);
  }, [obj, tenantUsers, setThisTenantUsers]);

  useEffect(() => {
    let thisAssinantes = assinantes.filter(
      (tu) => tu.tenant_codigo === obj.codigo,
    );
    setThisAssinantes(thisAssinantes);
  }, [obj, assinantes, setThisAssinantes]);

  const handleGetTenantsUsers = useCallback(async () => {
    setLoading(true);
    const usersResponse = await post(end.views.tenants_users, {
      tenant_codigo: obj.codigo,
    });
    setTenantUsers(usersResponse.data);
    setLoading(false);
  }, [obj, setTenantUsers]);

  // const handleGetPlanosProdutos = useCallback(async () => {
  //   setLoading(true);
  //   const planosProdutosResponse = await post(end.views.planos_produtos, {
  //     tenant_codigo: obj.codigo,
  //   });
  //   setPlanosProdutos(planosProdutosResponse.data);
  //   setLoading(false);
  // }, [obj, setPlanosProdutos]);

  const handleGetAssinantes = useCallback(async () => {
    setLoading(true);
    const assinantesResponse = await post(end.views.tenants_assinantes, {
      tenant_codigo: obj.codigo,
    });
    setAssinantes(assinantesResponse.data);
    setLoading(false);
  }, [obj, setAssinantes]);

  const handleAddUser = useCallback(
    async (e) => {
      e.preventDefault();

      if (addRole && addUser) {
        setLoading(true);
        const data = await post(end.tenants.users.create, {
          tenant_codigo: obj.codigo,
          role_codigo: addRole,
          user_codigo: addUser,
        });

        if (data.status === 200) {
          await handleGetTenantsUsers();
          setAddRole('');
          setAddUser('');
        }
        setLoading(false);
      }
    },
    [addRole, addUser, obj, handleGetTenantsUsers],
  );

  // const handleAddPlano = useCallback(
  //   async (e) => {
  //     e.preventDefault();

  //     if (addPlano) {
  //       setLoading(true);
  //       const data = await post(end.assinantes.create, {
  //         tenant_codigo: obj.codigo,
  //         plano_codigo: addPlano,
  //       });

  //       if (data.status === 200) {
  //         await handleGetPlanosProdutos();
  //         setAddPlano('');
  //       }
  //       setLoading(false);
  //     }
  //   },
  //   [addPlano, obj, handleGetPlanosProdutos],
  // );

  const handleAddAssinante = useCallback(
    async (e) => {
      e.preventDefault();

      if (addPlano) {
        setLoading(true);
        const data = await post(end.assinantes.create, {
          tenant_codigo: obj.codigo,
          plano_codigo: addPlano,
        });

        if (data.status === 200) {
          await handleGetAssinantes();
          setAddPlano('');
        }
        setLoading(false);
      }
    },
    [addPlano, obj, handleGetAssinantes],
  );

  return (
    <div id={`md_tenant_${obj.codigo}`}>
      <div>
        <div className='my-3'>
          <div className='d-flex flex-row justify-content-between my-2'>
            <h6 className='softxt fw300 fc-blue'>
              Adicionar Usuário ao Tenant
            </h6>
            <IconsUI
              info={ui.action.expand}
              clsnm='fc-blue hov-green hov-pointer'
              click={() => {
                document
                  .getElementById('mtenant_form_add_user')
                  .classList.toggle('div-hide');
              }}
            />
          </div>

          <Form
            id='mtenant_form_add_user'
            className='div-hide'
          >
            <Form.Group
              className='mb-3 d-flex flex-row justify-content-between align-items-center'
              controlId='form_tenant_modal_select_perfil'
            >
              <span className='wp-120 f09'>Atribuir Perfil</span>
              <Form.Select
                aria-label='Select perfil'
                size='sm'
                onChange={(e) => setAddRole(e.target.value)}
              >
                <option value=''></option>
                {roles &&
                  roles.map((role, index) => (
                    <option
                      key={index}
                      value={role.codigo}
                    >
                      {role.role}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <Form.Group
              className='mb-3 d-flex flex-row justify-content-between align-items-center'
              controlId='form_tenant_modal_select_user'
            >
              <span className='wp-120 f09'>Escolha o Usuário</span>
              <Form.Select
                aria-label='Select perfil'
                size='sm'
                onChange={(e) => setAddUser(e.target.value)}
              >
                <option value=''></option>
                {roles &&
                  users.map((user, index) => (
                    <option
                      key={index}
                      value={user.codigo}
                    >
                      {user.nome}
                      {' | '}
                      {user.email}
                      {' | '}
                      {user.telefone}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <div className='d-flex justify-content-center'>
              {loading ? (
                <Loading class_op='loading' />
              ) : (
                <Button
                  variant='primary'
                  type='submit'
                  size='sm'
                  onClick={handleAddUser}
                >
                  Adicionar Usuário ao Tenant: <b>{obj.tenant}</b>
                </Button>
              )}
            </div>
          </Form>
          <hr />
        </div>
        <div className='my-3'>
          <div className='d-flex flex-row justify-content-between my-2'>
            <h6 className='softxt fw300 fc-blue'>Usuários</h6>
            <IconsUI
              info={ui.action.expand}
              clsnm='fc-blue hov-green'
              click={() => {
                document
                  .getElementById('mtenant_table_users')
                  .classList.toggle('div-hide');
              }}
            />
          </div>

          <table
            className='table table-striped table-hover f09 table-vertical-center '
            id='mtenant_table_users'
          >
            <thead>
              <tr>
                <th scope='col'>Nome</th>
                <th scope='col'>Email</th>
                <th scope='col'>Telefone</th>
                <th scope='col'>Status</th>
                <th
                  scope='col'
                  colSpan={2}
                  className='fc-blue'
                >
                  Perfil
                </th>
                <th
                  scope='col'
                  colSpan={2}
                  className='fc-blue'
                >
                  Expiration
                </th>
              </tr>
            </thead>
            <tbody>
              {thisTenantUsers.map((tu, index) => (
                <TenantModalUsersLine
                  key={index}
                  tu={tu}
                  handleGetTenantsUsers={handleGetTenantsUsers}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div className='my-3'>
          <div className='d-flex flex-row justify-content-between my-2'>
            <h6 className='softxt fw300 fc-blue'>
              Adicionar Assinatura ao Tenant
            </h6>
            <IconsUI
              info={ui.action.expand}
              clsnm='fc-blue hov-green hov-pointer'
              click={() => {
                document
                  .getElementById('mtenant_form_add_subscription')
                  .classList.toggle('div-hide');
              }}
            />
          </div>

          <Form
            id='mtenant_form_add_subscription'
            className='div-hide'
          >
            <Form.Group
              className='mb-3 d-flex flex-row justify-content-between align-items-center'
              controlId='form_tenant_modal_select_perfil'
            >
              <span className='wp-120 f09'>Selecionar Plano</span>
              <Form.Select
                aria-label='Select plano'
                size='sm'
                onChange={(e) => setAddPlano(e.target.value)}
              >
                <option value=''></option>
                {planosProdutos &&
                  planosProdutos.map((plano, index) => (
                    <option
                      key={index}
                      value={plano.plano_codigo}
                    >
                      {plano.product_nome}
                      {' | '}
                      {plano.plano_nome}
                      {' | '}
                      {plano.frequencia_nome}
                      {' | '}
                      {currFormat(plano.plano_valor)}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>

            <div className='d-flex justify-content-center'>
              {loading ? (
                <Loading class_op='loading' />
              ) : (
                <Button
                  variant='primary'
                  type='submit'
                  size='sm'
                  onClick={handleAddAssinante}
                >
                  Adicionar Assinatura ao Tenant: <b>{obj.tenant}</b>
                </Button>
              )}
            </div>
          </Form>
          <hr />
        </div>

        <div className='my-3'>
          <div className='d-flex flex-row justify-content-between my-2'>
            <h6 className='softxt fw300 fc-blue'>Assinaturas</h6>
            <IconsUI
              info={ui.action.expand}
              clsnm='fc-blue hov-green'
              click={() => {
                document
                  .getElementById('mtenant_table_assinantes')
                  .classList.toggle('div-hide');
              }}
            />
          </div>

          <table
            className='table table-striped table-hover f09 table-vertical-center '
            id='mtenant_table_assinantes'
          >
            <thead>
              <tr>
                <th scope='col'>Plano</th>
                <th scope='col'>Ativo</th>
                <th scope='col'>Expiration</th>
              </tr>
            </thead>
            <tbody>
              {thisAssinantes.map((ta, index) => (
                <TenantModalAssinantesLine
                  key={index}
                  tu={ta}
                />
              ))}
            </tbody>
          </table>
        </div>

        <p>Faturas</p>

        <p>Logs</p>

        <p>Configurações</p>

        <p>Excluir Tenant</p>
      </div>
    </div>
  );
}

TenantModal.propTypes = {
  obj: PropTypes.object.isRequired,
};

export default TenantModal;
