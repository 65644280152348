import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MasterContext from './MasterContext';
import * as end from '../config/endpoints';
import { post } from '../services/requests';

function MasterProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('home');
  const [tenants, setTenants] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [messages, setMessages] = useState([]);
  const [roles, setRoles] = useState([]);
  const [planosProdutos, setPlanosProdutos] = useState([]);
  const [products, setProducts] = useState([]);
  const [plans, setPlans] = useState([]);
  const [frequencies, setFrequencies] = useState([]);
  const [assinantes, setAssinantes] = useState([]);
  const [users, setUsers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [supportTickets, setSupportTickets] = useState([]);
  const [supportMessages, setSupportMessages] = useState([]);
  const [tenantFiliais, setTenantFiliais] = useState([]);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    async function getData() {
      setMessage('Carregando dados...');
      const [
        rolesResponse,
        tenantsResponse,
        usersResponse,
        planosProdutosResponse,
        productsResponse,
        plansResponse,
        frequenciesResponse,
        assinantesResponse,
        invoicesResponse,
        tenantUsersResponse,
        tenantFiliaisResponse,

      ] = await Promise.all([
        post(end.roles.read.all),
        post(end.tenants.tenant.read.all),
        post(end.user.read.all),
        post(end.views.planos_produtos),
        post(end.produtos.read.all),
        post(end.planos.read.all),
        post(end.frequencias.read.all),
        post(end.views.tenants_assinantes),
        post(end.faturas.read.all),
        post(end.views.tenants_users),
        post(end.tenants.filiais.read.all)
      ]);

      setRoles(rolesResponse.data);
      setTenants(tenantsResponse.data);
      setUsers(usersResponse.data);
      setPlanosProdutos(planosProdutosResponse.data);
      setProducts(productsResponse.data);
      setPlans(plansResponse.data);
      setFrequencies(frequenciesResponse.data);
      setAssinantes(assinantesResponse.data);
      setInvoices(invoicesResponse.data);
      setTenantUsers(tenantUsersResponse.data);
      setTenantFiliais(tenantFiliaisResponse.data);
      setMessage('');

    }
    getData();
    setLoading(false);
  }, []);

  const context = {
    section,
    setSection,
    tenants,
    setTenants,
    tickets,
    setTickets,
    messages,
    setMessages,
    roles,
    setRoles,
    planosProdutos,
    setPlanosProdutos,
    products,
    setProducts,
    plans,
    setPlans,
    frequencies,
    setFrequencies,
    assinantes,
    setAssinantes,
    users,
    setUsers,
    invoices,
    setInvoices,
    supportTickets,
    setSupportTickets,
    supportMessages,
    setSupportMessages,
    tenantFiliais,
    setTenantFiliais,
    tenantUsers,
    setTenantUsers,
    loading,
    setLoading,
    message,
    setMessage,
  };

  return (
    <MasterContext.Provider value={context}>{children}</MasterContext.Provider>
  );
}

MasterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MasterProvider;
