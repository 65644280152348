import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import { formatCapitalizeFirst, formatCEP } from '../../assets/js/helpers';

export default function Clientes() {
  const consultarCNPJ = require('consultar-cnpj');

  const initialState = {
    tipoPessoa: '',
    documento: '',
    endereco: '',
    cep: '',
    whatsapp: '',
    email: '',
    nome: '',

    nacionalidade: '',
    naturalidade: '',
    profissao: '',
    nascimento: '',
    estadocivil: '',
    sexo: '',
    caracteristicaspessoais: '',
    nis: '',
    senhainss: '',
    rg: '',
    cpf: '',

    cnpj: '',
    estadual: '',
    municipal: '',
    representante: '',

    informacoes: '',
  };

  const [cliente, setCliente] = useState(initialState);

  async function getCEP(e) {
    e.preventDefault();
    let sanitizedCEP = e.target.value.replaceAll('-', '');
    const res = await fetch(`https://viacep.com.br/ws/${sanitizedCEP}/json/`);
    const data = await res.json();
    if (data.erro) {
      alert('CEP não encontrado');
    }
    setCliente({
      ...cliente,
      endereco: `${formatCapitalizeFirst(data.logradouro)}, n° ${
        data.numero
      }, bairro ${formatCapitalizeFirst(data.bairro)}, CEP ${
        data.cep
      }, ${formatCapitalizeFirst(data.localidade)}-${data.uf}`,
    });
  }

  async function getCNPJ(e) {
    e.preventDefault();
    let sanitizedCNPJ = e.target.value.replaceAll('.', '');
    sanitizedCNPJ = sanitizedCNPJ.replaceAll('-', '');
    sanitizedCNPJ = sanitizedCNPJ.replaceAll('/', '');

    const res = await consultarCNPJ(sanitizedCNPJ);
    if (res) {
      console.log(res);

      setCliente({
        ...cliente,
        nome: res.razao_social,
        endereco: `${formatCapitalizeFirst(
          res.estabelecimento.tipo_logradouro,
        )} ${formatCapitalizeFirst(res.estabelecimento.logradouro)}, n° ${
          res.estabelecimento.numero
        }, bairro ${formatCapitalizeFirst(
          res.estabelecimento.bairro,
        )}, CEP ${formatCEP(res.estabelecimento.cep)}, ${formatCapitalizeFirst(
          res.estabelecimento.cidade.nome,
        )}-${res.estabelecimento.estado.sigla}`,
        cep: formatCEP(res.estabelecimento.cep),
        email: res.estabelecimento.email,
        informacoes: `${res.estabelecimento.ddd1} ${res.estabelecimento.telefone1}`,
        representante: res.socios[0].nome,
        cnpj: res.cnpj,
        estadual:
          res.estabelecimento.inscricoes_estaduais.length > 0 &&
          res.estabelecimento.inscricoes_estaduais[0].inscricao_estadual,
      });
    }
  }

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Clientes</h4>
      <>
        <Form className='form-base'>
          <div className='d-flex flex-row justify-content-between gocol950'>
            <FloatingLabel
              label='Tipo de Cliente'
              className='my-1'
            >
              <Form.Select
                aria-label='TipoPessoa'
                size='sm'
                className='wp-200'
                onChange={(e) =>
                  setCliente({ ...cliente, tipoPessoa: e.target.value })
                }
              >
                <option></option>
                <option value='PF'>Pessoa Física</option>
                <option value='PJ'>Pessoa Jurídica</option>
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              label='E-mail'
              className=' my-1 wp-300 inputme1'
            >
              <Form.Control
                type='email'
                id='email'
                placeholder='email'
                onChange={(e) =>
                  setCliente({ ...cliente, email: e.target.value })
                }
                value={cliente.email}
              />
            </FloatingLabel>
            <FloatingLabel
              label='Whatsapp'
              className='my-1 wp-200 inputme1'
            >
              <Form.Control
                type='text'
                id='whatsapp'
                placeholder='whatsapp'
                as={IMaskInput}
                mask='(00) 00000-0000'
                onChange={(e) =>
                  setCliente({ ...cliente, whatsapp: e.target.value })
                }
                value={cliente.whatsapp}
              />
            </FloatingLabel>
          </div>

          <div
            className={cliente.tipoPessoa === '' ? 'd-none' : 'd-block'}
            id='selectTipoPessoa'
          >
            <FloatingLabel
              label={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
              className='my-1'
            >
              <Form.Control
                as={IMaskInput}
                type='text'
                id='documento'
                placeholder={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
                mask={
                  cliente.tipoPessoa === 'PF'
                    ? '000.000.000-00'
                    : '**.***.***/****-**'
                }
                onChange={(e) => {
                  setCliente({ ...cliente, documento: e.target.value });
                }}
                onBlur={(e) => {
                  if (
                    cliente.tipoPessoa === 'PJ' &&
                    e.target.value.length === 18
                  ) {
                    getCNPJ(e);
                  }
                }}
              />
            </FloatingLabel>

            <FloatingLabel
              label={cliente.tipoPessoa === 'PF' ? 'Nome' : 'Razão Social'}
              className='my-1'
            >
              <Form.Control
                type='text'
                placeholder={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
                id='nome'
                value={cliente.nome}
                onChange={(e) =>
                  setCliente({ ...cliente, nome: e.target.value })
                }
              />
            </FloatingLabel>

            <div className='d-flex flex-row'>
              <FloatingLabel
                label='Endereço'
                className='my-1 w100 me-1'
              >
                <Form.Control
                  type='text'
                  placeholder='Endereço'
                  id='endereco'
                  className='w100'
                  value={cliente.endereco}
                  onChange={(e) =>
                    setCliente({ ...cliente, endereco: e.target.value })
                  }
                />
              </FloatingLabel>

              <FloatingLabel
                label='CEP'
                className='my-1'
              >
                <Form.Control
                  type='text'
                  className='wp-120'
                  as={IMaskInput}
                  mask='00000-000'
                  placeholder='CEP'
                  id='cep'
                  value={cliente.cep}
                  onChange={(e) =>
                    setCliente({ ...cliente, cep: e.target.value })
                  }
                  onBlur={(e) => {
                    if (e.target.value.length === 9) {
                      getCEP(e);
                    }
                  }}
                />
              </FloatingLabel>
            </div>

            {cliente.tipoPessoa === 'PJ' ? (
              <FloatingLabel
                label='Representante'
                className='my-1'
              >
                <Form.Control
                  type='text'
                  placeholder='Representante'
                  id='endereco'
                  value={cliente.representante}
                  onChange={(e) =>
                    setCliente({ ...cliente, representante: e.target.value })
                  }
                />
              </FloatingLabel>
            ) : null}

            {cliente.tipoPessoa === 'PF' ? (
              // nacionalidade: '',
              // naturalidade: '',
              // profissao: '',
              // nascimento: '',
              // estadocivil: '',
              // sexo: '',
              // caracteristicaspessoais: '',
              // nis: '',
              // senhainss: '',
              // rg: '',
              <>
                <FloatingLabel
                  label='Nacionalidade'
                  className='my-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Nacionalidade'
                    id='endereco'
                    value={cliente.nacionalidade}
                    onChange={(e) =>
                      setCliente({ ...cliente, nacionalidade: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Naturalidade'
                  className='my-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Naturalidade'
                    id='endereco'
                    value={cliente.naturalidade}
                    onChange={(e) =>
                      setCliente({ ...cliente, naturalidade: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Profissão'
                  className='my-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Profissão'
                    id='endereco'
                    value={cliente.profissao}
                    onChange={(e) =>
                      setCliente({ ...cliente, profissao: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Nascimento'
                  className='my-1'
                >
                  <Form.Control
                    type='date'
                    placeholder='Nascimento'
                    id='endereco'
                    value={cliente.nascimento}
                    onChange={(e) =>
                      setCliente({ ...cliente, nascimento: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Gênero'
                  className='my-1'
                >
                  <Form.Select
                    aria-label='Gênero'
                    size='sm'
                    className='my-1'
                    onChange={(e) =>
                      setCliente({ ...cliente, genero: e.target.value })
                    }
                  >
                    <option></option>
                    <option value='F'>Feminino</option>
                    <option value='M'>Masculino</option>
                  </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                  label='Estado Civil'
                  className='my-1'
                >
                  <Form.Select
                    aria-label='Estado Civil'
                    size='sm'
                    className='my-1'
                    onChange={(e) =>
                      setCliente({ ...cliente, estadocivil: e.target.value })
                    }
                  >
                    <option></option>
                    <option
                      value={cliente.genero === 'F' ? 'Solteira' : 'Solteiro'}
                    >
                      {cliente.genero === 'F' ? 'Solteira' : 'Solteiro'}
                    </option>
                    <option
                      value={cliente.genero === 'F' ? 'Casada' : 'Casado'}
                    >
                      {cliente.genero === 'F' ? 'Casada' : 'Casado'}
                    </option>
                    <option
                      value={
                        cliente.genero === 'F' ? 'Divorciada' : 'Divorciado'
                      }
                    >
                      {cliente.genero === 'F' ? 'Divorciada' : 'Divorciado'}
                    </option>
                    <option value={cliente.genero === 'F' ? 'Viúva' : 'Viúvo'}>
                      {cliente.genero === 'F' ? 'Viúva' : 'Viúvo'}
                    </option>
                    <option value='convivendo em união estável'>
                      convivendo em união estável
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </>
            ) : null}

            <Button
              variant='primary'
              type='submit'
            >
              Salvar
            </Button>
          </div>
        </Form>
      </>
    </div>
  );
}
