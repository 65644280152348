import { requestPost } from './requestPost';
import { auth } from '../config/endpoints';

export default async function recover(email, cpf) {
  const response = await requestPost(auth.recover, {
    email,
    cpf,
  });

  return response;
}
