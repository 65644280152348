import { useContext } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import GlobalContext from '../context/GlobalContext';
import '../assets/css/home.css';
import logo from '../assets/img/logo.png';
import wookcalc from '../assets/img/home/wookcalc.png';
import wookjuris from '../assets/img/home/wookjuris.png';
import wookhabita from '../assets/img/home/wookhabita.png';
import wookclinic from '../assets/img/home/wookclinic.png';

export default function Home() {
  const { loading } = useContext(GlobalContext);
  // const [logged, setLogged] = useState(false);

  // useEffect(() => {
  //   const islogged = checkLogin();
  //   setLogged(islogged);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  if (loading) return <LoadingFull />;

  // if (logged) navigate('/portal');

  return (
    <>
      <NavBar />
      <main className='container'>
        <div className='d-flex flex-row'>
          <section className='main-container-render'>
            <div className='main-home'>
              <img
                src={logo}
                alt=''
                className='logo-main'
              />
              <p className='fw400 f12 fc-regular py-3'>
                Soluções inteligentes em software
              </p>
            </div>

            <section
              id='assinaturas'
              className='pricing'
            >
              <div className='container'>
                <p className='h3 text-center py-3 fc-blue fw600'>Assine já</p>

                <div className='row gy-4'>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box'>
                      <h3>Calculadora</h3>
                      <div className='price'>
                        <sup>R$</sup>9<span> / mês</span>
                      </div>
                      <img
                        src={wookcalc}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box'>
                      <h3>Juris</h3>
                      <div className='price'>
                        <sup>R$</sup>79<span> / mês</span>
                      </div>
                      <img
                        src={wookjuris}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box'>
                      <h3>Habitacional</h3>
                      <div className='price'>
                        <sup>R$</sup>79<span> / mês</span>
                      </div>
                      <img
                        src={wookhabita}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box'>
                      <h3>Clinic</h3>
                      <div className='price'>
                        <sup>R$</sup>59<span> / mês</span>
                      </div>
                      <img
                        src={wookclinic}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>
                  <p className='py-2 text-center'>
                    Para assinar qualquer serviço, faça login e acesse a área de
                    usuário
                  </p>
                </div>
              </div>
            </section>

          </section>
        </div>
      </main>
    </>
  );
}
