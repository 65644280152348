// import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app/root.css';
import './assets/css/app/font.css';
import './assets/css/app/base.css';
import './assets/css/app/color.css';
import './assets/css/app/svg.css';
import './assets/css/style.css';
import GlobalProvider from './context/GlobalProvider';
import AppRoutes from './routes';

function App() {
  return (
    <GlobalProvider>
      <AppRoutes />
    </GlobalProvider>
  );
}

export default App;
