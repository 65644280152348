import axios from 'axios';
import { OCI } from './endpoints';

const api = axios.create({
  baseURL: OCI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const requestPost = async (endpoint, body) => {
  const response = await api.post(`${endpoint}/`, body);
  return response;
};

export default api;
