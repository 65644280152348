import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/navbar.css';
import logojuris from '../assets/img/juris/wookjuris1024.png';
import logo from '../assets/img/logo.png';
import GlobalContext from '../context/GlobalContext';

export default function NavBar() {
  const { navigate, logout, checkLogin, produto } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const islogged = checkLogin();
    setLogged(islogged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (path) => () => navigate(path);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const setLogo = () => {
    switch (produto) {
      case 'juris':
        return logojuris;
      default:
        return logo;
    }
  };

  if (logged) {
    return (
      <>
        <div className='navbar-top'>
          <div className='container d-flex flex-row align-content-center justify-content-between py-3 px-1'>
            <a
              href='/'
              rel='noreferrer'
              className='wp-200'
            >
              <img
                src={logo}
                className='navbar-logo'
                alt='logo'
              />
            </a>
            <div className='d-flex flex-row justify-content-end'>
            <span
                className='sp-link '
                onClick={handleClick('/portal')}
              >
                Portal
              </span>

              <span
                className='sp-link '
                onClick={handleClick('/user')}
              >
                Minha Conta
              </span>

              <span
                className='sp-link '
                onClick={() => handleLogout()}
              >
                Sair
              </span>
            </div>
          </div>
        </div>
        {produto !== 'global' && (
          <div className='navbar-product'>
            <div className='container'>
              <img
                src={setLogo()}
                className='navbar-logo-product'
                alt='logo'
              />
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div className='navbar-top'>
        <div className='container d-flex flex-row align-content-center justify-content-between py-3 px-1'>
          <a
            href='/'
            rel='noreferrer'
            className='wp-200'
          >
            <img
              src={logo}
              className='navbar-logo'
              alt='logo'
            />
          </a>
          <div className='d-flex flex-row justify-content-end'>
            <span
              className='sp-link '
              onClick={handleClick('/login')}
            >
              Login
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
