// import React, { useContext } from 'react';
// import GlobalContext from '../../context/GlobalContext';

export default function Home() {
  // const { store } = useContext(GlobalContext);

  // const { assinantes } = store();

  return (
    <div className='ms-3'>
      <h4 className='softxt'>Home</h4>
    </div>
  );
}
