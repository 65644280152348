import PropTypes from 'prop-types';
import React from 'react';
import { Oval } from 'react-loader-spinner';

const Loading = ({ class_op, sm = false }) => (
  <div className={`text-center ${class_op}`}>
    <Oval
      visible={true}
      height={sm ? 25 : 50}
      width={sm ? 25 : 50}
      color='#4fa94d'
      ariaLabel='oval-loading'
      wrapperStyle={{}}
      wrapperClass=''
    />
  </div>
);

Loading.propTypes = {
  class_op: PropTypes.string,
};

export default Loading;
