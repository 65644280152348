import React, { useContext } from 'react';
import { datahora } from '../../assets/js/helpers';
import MasterContext from '../../context/MasterContext';
import Loading from '../Loading';

export default function Subscribers() {
  const { loading, assinantes } = useContext(MasterContext);

  if (loading) return <Loading class_op='loading' />;

  return (
    <div className=''>
      <h4>Assinantes</h4>

      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>tenant_codigo</th>
            <th scope='col'>plano_codigo</th>
            <th scope='col'>active</th>
            <th scope='col'>expiration</th>
            <th scope='col'>support</th>
            <th scope='col'>updates</th>
            <th scope='col'>Código</th>
            <th scope='col'>Created</th>
            <th scope='col'>Updated</th>
          </tr>
        </thead>
        <tbody>
          {assinantes &&
            assinantes.map((d, index) => (
              <tr key={index}>
                <th scope='row'>{d.id}</th>
                <td>{d.tenant_codigo}</td>
                <td>{d.plano_codigo}</td>
                <td>{d.active}</td>
                <td>{d.expiration}</td>
                <td>{d.support}</td>
                <td>{d.updates}</td>
                <td>{d.codigo}</td>
                <td>{datahora(d.created_at)}</td>
                <td>{datahora(d.updated_at)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
