import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function BadgeOnOff({ on, clsnm = '', click = null }) {
  const variant = on ? 'success' : 'danger';
  const text = on ? 'ON' : 'OFF';
  const textColor = on ? 'white' : 'black';

  return (
    <Badge
      bg={variant}
      text={textColor}
      onClick={click}
      className={clsnm}
    >
      {text}
    </Badge>
  );
}
