import React, { useContext } from 'react';
import JurisContext from '../../context/JurisContext';
// import MasterContext from '../../context/MasterContext';
// import Users from './Users';
// import Tenants from './Tenants';
// import Home from './Home';
// import Invoices from './Invoices';
// import Products from './Products';
// import Settings from './Settings';
// import Payments from './Clientes';
import Clientes from './Clientes';
import Home from './Home';
import Settings from './Settings';

export default function RenderSection() {
  const { section } = useContext(JurisContext);

  switch (section) {
    case 'clientes':
      return <Clientes />;

    case 'settings':
      return <Settings />;

    default:
      return <Home />;
  }
}
