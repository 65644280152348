import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import GlobalContext from './GlobalContext';
import FormHeader from '../components/FormHeader';
import LoadingFull from '../components/LoadingFull';

export default function Login() {
  const [errorMessage, setErrorMessage] = useState();

  const { navigate, login, loading, setLoading } = useContext(GlobalContext);

  const userlogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    try {
      const response = await login({ email, password });
      const { success, message } = response;
      if (!success) {
        setErrorMessage(message);
      } else {
        setLoading(false);
        navigate('/');
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  if (loading)
    return (
      <>
        <LoadingFull />
      </>
    );

  return (
    <div className='wrapperform'>
      <header className='form-header text-center'>
        <FormHeader formname={'Faça o login'} />
      </header>
      <div className='w60 mw600'>
        <form
          action=''
          className='w-100'
        >
          <Form.Label
            htmlFor='email'
            className='input-label'
          >
            E-mail
          </Form.Label>
          <Form.Control
            type='email'
            id='email'
            autoComplete='email'
            name='email'
            aria-describedby='email'
            // onChange={async (e) => handleEmail(e)}
            className=''
          />
          {/* <p className='mt-0 mb-4 f08 fc-red'>{errorMessage}</p> */}

          <Form.Label
            htmlFor='password'
            className='input-label'
          >
            Password
          </Form.Label>
          <Form.Control
            type='password'
            id='password'
            name='password'
            autoComplete='current-password'
            className='mb-4'
          />

          <p className='text-center mt-3 mb-4 f08 fc-red fw500'>
            {errorMessage}
          </p>

          <div className='d-flex flex-row justify-content-around my-2'>
            <Link to='/recover'>
              <Button
                className='no_under'
                variant='link'
              >
                Esqueci a senha
              </Button>
            </Link>
            <Button
              variant='primary'
              onClick={(event) => userlogin(event)}
            >
              Efetuar Login
            </Button>
          </div>

          <div className='text-center mt-4'>
            <Link to='/register'>
              <Button
                className='no_under'
                variant='link'
              >
                Primeiro acesso? Cadastre-se
              </Button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
