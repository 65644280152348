import React, { useContext } from 'react';
import MasterContext from '../../context/MasterContext';
import Loading from '../Loading';
import TenantsLine from './TenantsLine';

export default function Tenants() {
  const { loading, tenants } = useContext(MasterContext);

  if (loading) return <Loading class_op='loading' />;

  return (
    <div className=''>
      <h4>Tenants</h4>

      <table className='table table-striped table-hover'>
        <thead>
          <tr>
            <th scope='col'>#</th>
            <th scope='col'>Tenant</th>
            <th scope='col'>Email</th>
            <th scope='col'>Código</th>
            <th scope='col'>Ativo</th>
            <th scope='col'>Created</th>
            <th scope='col'>Updated</th>
            <th scope='col'></th>
          </tr>
        </thead>
        <tbody>
          {tenants &&
            tenants.map((d, index) => (
              <TenantsLine
                key={`key_tenant_line_${d.codigo}`}
                d={d}
                index={index}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
