import React, { useContext } from 'react';
import '../../assets/css/navbar.css';
import JurisContext from '../../context/JurisContext';

import BlobImage from '../BlobImage';

export default function TenantBar() {
  const { jurisStore, setLogoParams } = useContext(JurisContext);

  console.log(jurisStore());
  console.log(jurisStore().assinantes);

  // useEffect(() => {
  //   const logged = checkLogin();
  //   if (!logged) navigate('/login');
  // }, [checkLogin, navigate]);

  return (
    <div className='container d-flex align-items-center justify-content-between my-3'>
      <p className='f14 fw500'></p>
      <BlobImage
        blobCode={jurisStore().assinantes[0].TENANT_CODIGO}
        blobTable={'TENANTS'}
        tenantCode={jurisStore().assinantes[0].TENANT_CODIGO}
        tenant={jurisStore().assinantes[0].TENANT_TENANT}
        token={jurisStore().token}
        setParams={setLogoParams}
      />
    </div>
  );
}
