import React from 'react';
// import { tenant } from '../../config/endpoints';
// import { post } from '../../services/requests';
// import Loading from '../Loading';

export default function Products() {
  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   async function get_data() {
  //     setLoading(true);
  //     const response = await post(tenant.read.all);
  //     setData(response.data);
  //     setLoading(false);
  //     return response.data;
  //   }
  //   get_data();
  // }, []);

  // if (loading) return <Loading class_op='loading' />;

  return (
    <div className=''>
      <h4>Products</h4>
    </div>
  );
}
