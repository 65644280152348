import React from 'react';
import BadgeOnOff from '../BadgeOnOff';

// import Loading from '../Loading';
// import * as end from '../../config/endpoints';
// import { post } from '../../services/requests';

export default function TenantModalAssinantesLine({ tu, index }) {
  // const [loadingOnOffAssinante, setLoadingOnOffAssinante] = useState(false);

  return (
    <tr key={index}>
      <td>{tu.plano_nome}</td>
      <td>
        {/* {loadingOnOffAssinante ? (
          <Loading
            class_op='loading'
            sm={true}
          />
        ) : (
          <BadgeOnOff
            clsnm='hov-pointer badgeonoff'
            on={tu.assinante_active}
           
          />
        )} */}
        <BadgeOnOff
          clsnm='hov-pointer badgeonoff'
          on={tu.assinante_active}
        />
      </td>
      <td>{tu.expiration}</td>
    </tr>
  );
}
