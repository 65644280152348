import React, { useContext } from 'react';
import MasterContext from '../../context/MasterContext';
import Driller from './Driller';
import Home from './Home';
import Invoices from './Invoices';
import Payments from './Payments';
import Products from './Products';
import Settings from './Settings';
import Subscribers from './Subscribers';
import Tenants from './Tenants';
import Users from './Users';

export default function RenderSection() {
  const { section } = useContext(MasterContext);

  switch (section) {

    case 'driller':
      return <Driller />;
    case 'users':
      return <Users />;
    case 'tenants':
      return <Tenants />;
    case 'home':
      return <Home />;
    case 'payments':
      return <Payments />;
    case 'invoices':
      return <Invoices />;
    case 'products':
      return <Products />;
    case 'settings':
      return <Settings />;
      case 'subscribers':
        return <Subscribers />;
  
    default:
      return <Home />;
  }
}
