import { requestPost } from './requestPost';
import { auth } from '../config/endpoints';

export default async function register(cpf, nome, email, telefone, password) {
  const response = await requestPost(auth.register, {
    cpf,
    nome,
    email,
    telefone,
    password,
  });

  return response;
}
