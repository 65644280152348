import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import JurisContext from './JurisContext';
import GlobalContext from './GlobalContext';

function JurisProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('home');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [logoParams, setLogoParams] = useState({width: 180, height: 30});

  const { navigate, checkLogin, store } = useContext(GlobalContext);

  const { tenant } = useParams();
  sessionStorage.setItem('@App:tenant', tenant);
  const [useTenant, setUseTenant] = useState(tenant);

  const { assinantes } = store();

  useEffect(() => {
    const logged = checkLogin();
    if (!logged) navigate('/login');
    const info =
      assinantes &&
      assinantes.find((assinante) => assinante.TENANT_TENANT === tenant);
      sessionStorage.setItem('@App:infoTenant', JSON.stringify(info));
    // eslint-disable-next-line
  }, []);

  function jurisStore() {
    return {
      ...store(),
      tenant: sessionStorage.getItem('@App:tenant'),
      infoTenant: JSON.parse(sessionStorage.getItem('@App:infoTenant')),
    };
  }

  const context = {
    jurisStore,
    navigate,
    loading,
    setLoading,
    section,
    setSection,
    isSubscribed,
    setIsSubscribed,
    useTenant,
    setUseTenant,
    logoParams,
    setLogoParams,
  };

  return (
    <JurisContext.Provider value={context}>{children}</JurisContext.Provider>
  );
}

JurisProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default JurisProvider;
