export const OCI =
  'https://g3c32193a94f6bf-bewook.adb.sa-saopaulo-1.oraclecloudapps.com/ords';

export const schemas = {
  global: `/global`,
  apoio: `/apoio`,
  juris: `/juris`,
};

export const global = {
  auth: `${schemas.global}/auth`,
  vortex: `${schemas.global}/vortex`,
  roles: `${schemas.global}/roles`,
  tenants: {
    tenant: `${schemas.global}/tenants/tenant`,
    filiais: `${schemas.global}/tenants/filiais`,
    users: `${schemas.global}/tenants/users`,
  },
  users: `${schemas.global}/users`,
  planos: `${schemas.global}/planos`,
  assinantes: `${schemas.global}/assinantes`,
  faturas: `${schemas.global}/faturas`,
  frequencias: `${schemas.global}/frequencias`,
  produtos: `${schemas.global}/produtos`,
  pagamentos: `${schemas.global}/pagamentos`,
  support: {
    tickets: `${schemas.global}/support_tickets`,
    messages: `${schemas.global}/support_messages`,
  },
  views: `${schemas.global}/views`,
};

export const roles = {
  create: `${global.roles}/c`,
  read: {
    id: `${global.roles}/r/id`,
    codigo: `${global.roles}/r/codigo`,
    all: `${global.roles}/r/all`,
  },
  update: {
    codigo: `${global.roles}/u/codigo`,
    nome: `${global.roles}/u/nome`,
    email: `${global.roles}/u/email`,
    active: `${global.roles}/u/active`,
  },
  delete: {
    id: `${global.roles}/d/id`,
    codigo: `${global.roles}/d/codigo`,
  },
};

export const tenants = {
  tenant: {
    create: `${global.tenants.tenant}/c`,
    read: {
      id: `${global.tenants.tenant}/r/id`,
      codigo: `${global.tenants.tenant}/r/codigo`,
      logo: `${global.tenants.tenant}/r/logo`,
      timbrado: `${global.tenants.tenant}/r/timbrado`,
      all: `${global.tenants.tenant}/r/all`,
    },
    update: {
      codigo: `${global.tenants.tenant}/u/codigo`,
      nome: `${global.tenants.tenant}/u/nome`,
      email: `${global.tenants.tenant}/u/email`,
      active: `${global.tenants.tenant}/u/active`,
      all: `${global.tenants.tenant}/u/all`,
      logo: `${global.tenants.tenant}/u/logo`,
      timbrado: `${global.tenants.tenant}/u/timbrado`,
    },
    delete: {
      id: `${global.tenants.tenant}/d/id`,
      codigo: `${global.tenants.tenant}/d/codigo`,
    },
  },
  filiais: {
    create: `${global.tenants.filiais}/c`,
    read: {
      id: `${global.tenants.filiais}/r/id`,
      codigo: `${global.tenants.filiais}/r/codigo`,
      all: `${global.tenants.filiais}/r/all`,
    },
    update: {
      codigo: `${global.tenants.filiais}/u/codigo`,
      nome: `${global.tenants.filiais}/u/nome`,
      email: `${global.tenants.filiais}/u/email`,
      active: `${global.tenants.filiais}/u/active`,
    },
    delete: {
      id: `${global.tenants.filiais}/d/id`,
      codigo: `${global.tenants.filiais}/d/codigo`,
    },
  },
  users: {
    create: `${global.tenants.users}/c`,
    read: {
      id: `${global.tenants.users}/r/id`,
      codigo: `${global.tenants.users}/r/codigo`,
      all: `${global.tenants.users}/r/all`,
    },
    update: {
      codigo: `${global.tenants.users}/u/codigo`,
      nome: `${global.tenants.users}/u/nome`,
      email: `${global.tenants.users}/u/email`,
      active: `${global.tenants.users}/u/active`,
    },
    delete: {
      id: `${global.tenants.users}/d/id`,
      codigo: `${global.tenants.users}/d/codigo`,
    },
  },
};

export const auth = {
  login: `${global.auth}/login`,
  logout: `${global.auth}/logout`,
  recover: `${global.auth}/recover`,
  reset: `${global.auth}/reset`,
};

export const vortex = {
  login: `${global.vortex}/login`,
  logout: `${global.vortex}/logout`,
};

// const global = {
//   users: '/users',
//   auth: '/auth',
//   tenants: {
//     tenant: '/tenants',
//     filiais: '/tenantsfiliais',
//     users: '/tenantsusers',
//   },
//   planos: '/planos',
//   assinantes: '/assinantes',
//   faturas: '/faturas',
//   frequencias: '/frequencias',
//   produtos: '/produtos',
//   pagamentos: '/pagamentos',
//   roles: '/roles',
//   support: {
//     tickets: '/support_tickets',
//     messages: '/support_messages'
//   },
//   views: '/views',
// }

export const users = {
  create: `${global.users}/c`,
  read: {
    email: `${global.users}/r/email`,
    cpf: `${global.users}/r/cpf`,
    id: `${global.users}/r/id`,
    codigo: `${global.users}/r/codigo`,
    all: `${global.users}/r/all`,
  },
  update: {
    email: `${global.users}/u/email`,
    cpf: `${global.users}/u/cpf`,
    telefone: `${global.users}/u/telefone`,
    password: `${global.users}/u/password`,
    active: `${global.users}/u/active`,
  },

  delete: {
    id: `${global.users}/d/id`,
    codigo: `${global.users}/d/codigo`,
  },
};

export const assinantes = {
  create: `${global.assinantes}/c`,
  read: {
    id: `${global.assinantes}/r/id`,
    codigo: `${global.assinantes}/r/codigo`,
    all: `${global.assinantes}/r/all`,
    tenant: `${global.assinantes}/r/tenant/all`,
  },
  update: {
    codigo: `${global.assinantes}/u/codigo`,
    nome: `${global.assinantes}/u/nome`,
    email: `${global.assinantes}/u/email`,
    active: `${global.assinantes}/u/active`,
  },
  delete: {
    id: `${global.assinantes}/d/id`,
    codigo: `${global.assinantes}/d/codigo`,
  },
};

export const planos = {
  create: `${global.planos}/c`,
  read: {
    id: `${global.planos}/r/id`,
    codigo: `${global.planos}/r/codigo`,
    all: `${global.planos}/r/all`,
  },
  update: {
    codigo: `${global.planos}/u/codigo`,
    nome: `${global.planos}/u/nome`,
    email: `${global.planos}/u/email`,
    active: `${global.planos}/u/active`,
  },
  delete: {
    id: `${global.planos}/d/id`,
    codigo: `${global.planos}/d/codigo`,
  },
};

export const faturas = {
  create: `${global.faturas}/c`,
  read: {
    id: `${global.faturas}/r/id`,
    codigo: `${global.faturas}/r/codigo`,
    all: `${global.faturas}/r/all`,
  },
  update: {
    codigo: `${global.faturas}/u/codigo`,
    nome: `${global.faturas}/u/nome`,
    email: `${global.faturas}/u/email`,
    active: `${global.faturas}/u/active`,
  },
  delete: {
    id: `${global.faturas}/d/id`,
    codigo: `${global.faturas}/d/codigo`,
  },
};

export const frequencias = {
  create: `${global.frequencias}/c`,
  read: {
    id: `${global.frequencias}/r/id`,
    codigo: `${global.frequencias}/r/codigo`,
    all: `${global.frequencias}/r/all`,
  },
  update: {
    codigo: `${global.frequencias}/u/codigo`,
    nome: `${global.frequencias}/u/nome`,
    email: `${global.frequencias}/u/email`,
    active: `${global.frequencias}/u/active`,
  },
  delete: {
    id: `${global.frequencias}/d/id`,
    codigo: `${global.frequencias}/d/codigo`,
  },
};

export const produtos = {
  create: `${global.produtos}/c`,
  read: {
    id: `${global.produtos}/r/id`,
    codigo: `${global.produtos}/r/codigo`,
    all: `${global.produtos}/r/all`,
  },
  update: {
    codigo: `${global.produtos}/u/codigo`,
    nome: `${global.produtos}/u/nome`,
    email: `${global.produtos}/u/email`,
    active: `${global.produtos}/u/active`,
  },
  delete: {
    id: `${global.produtos}/d/id`,
    codigo: `${global.produtos}/d/codigo`,
  },
};

export const pagamentos = {
  create: `${global.pagamentos}/c`,
  read: {
    id: `${global.pagamentos}/r/id`,
    codigo: `${global.pagamentos}/r/codigo`,
    all: `${global.pagamentos}/r/all`,
  },
  update: {
    codigo: `${global.pagamentos}/u/codigo`,
    nome: `${global.pagamentos}/u/nome`,
    email: `${global.pagamentos}/u/email`,
    active: `${global.pagamentos}/u/active`,
  },
  delete: {
    id: `${global.pagamentos}/d/id`,
    codigo: `${global.pagamentos}/d/codigo`,
  },
};

export const support = {
  tickets: {
    create: `${global.support.tickets}/c`,
    read: {
      id: `${global.support.tickets}/r/id`,
      codigo: `${global.support.tickets}/r/codigo`,
      all: `${global.support.tickets}/r/all`,
    },
    update: {
      codigo: `${global.support.tickets}/u/codigo`,
      nome: `${global.support.tickets}/u/nome`,
      email: `${global.support.tickets}/u/email`,
      active: `${global.support.tickets}/u/active`,
    },
    delete: {
      id: `${global.support.tickets}/d/id`,
      codigo: `${global.support.tickets}/d/codigo`,
    },
  },
  messages: {
    create: `${global.support.messages}/c`,
    read: {
      id: `${global.support.messages}/r/id`,
      codigo: `${global.support.messages}/r/codigo`,
      all: `${global.support.messages}/r/all`,
    },
    update: {
      codigo: `${global.support.messages}/u/codigo`,
      nome: `${global.support.messages}/u/nome`,
      email: `${global.support.messages}/u/email`,
      active: `${global.support.messages}/u/active`,
    },
    delete: {
      id: `${global.support.messages}/d/id`,
      codigo: `${global.support.messages}/d/codigo`,
    },
  },
};

export const views = {
  planos_produtos: `${global.views}/planos_produtos`,
  tenants_users: `${global.views}/tenants_users`,
  tenants_assinantes: `${global.views}/tenants_assinantes`,
  tenants_assinantes_users: `${global.views}/tenants_assinantes_users`,
};
