import React, { useContext } from 'react';
import { data_br, datahora } from '../../assets/js/helpers';
import MasterContext from '../../context/MasterContext';
import Loading from '../Loading';

export default function Users() {
  const { loading, users } = useContext(MasterContext);

  if (loading)  return <Loading class_op='loading' />;

  return (
    <div className=''>

        <h4>Usuários</h4>

        <table className='table table-striped table-hover text-center'>
          <thead>
            <tr>
              <th scope='col'>#</th>
              <th scope='col'>Nome</th>
              <th scope='col'>Email</th>
              <th scope='col'>Telefone</th>
              <th scope='col'>CPF</th>
              <th scope='col'>Código</th>
              <th scope='col'>Ativo</th>
              <th scope='col'>Created</th>
              <th scope='col'>Updated</th>

            </tr>
          </thead>
          <tbody>
            {users && users.map((d, index) => (
              <tr key={index}>
                <th scope='row'>{d.id}</th>
                <td>{d.nome}</td>
                <td>{d.email}</td>
                <td>{d.telefone}</td>
                <td>{d.cpf}</td>
                <td>{d.codigo}</td>
                <td>{d.active}</td>	
                <td>{data_br(d.created_at)}</td>
                <td>{datahora(d.updated_at)}</td>
              </tr>
            ))}
          </tbody>
        </table>

    </div>
  );
}
