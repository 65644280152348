import PropTypes from 'prop-types';
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import logo from '../assets/img/logo.png';

const LoadingFull = ({ class_op }) => (
  <div
    className={`d-flex flex-column justify-content-center align-items-center  text-center w100 h100h p-3 ${class_op}`}
  >
    <img
      src={logo}
      className='loading-logo'
      alt='logo'
    />
    <ThreeDots
      height='100'
      width='100'
      color='#007dca'
      ariaLabel='bars-loading'
      wrapperStyle={{}}
      wrapperClass=''
      visible={true}
    />
    <p className='mt-4 mb-2 f09  softxt'>Aguarde...</p>
  </div>
);

LoadingFull.propTypes = {
  class_op: PropTypes.string,
};

export default LoadingFull;
