const base = {
  users: '/global/users',
  auth: '/global/auth',
  tenants: {
    tenant: '/global/tenants',
    filiais: '/global/tenantsfiliais',
    users: '/global/tenantsusers',
  },
  planos: '/global/planos',
  assinantes: '/global/assinantes',
  faturas: '/global/faturas',
  frequencias: '/global/frequencias',
  produtos: '/global/produtos',
  pagamentos: '/global/pagamentos',
  roles: '/global/roles',
  support: {
    tickets: '/global/support_tickets',
    messages: '/global/support_messages'
  },
  views: '/global/views',
}

export const auth = {
  login: `${base.auth}/login`,
  register: `${base.auth}/register`,
  recover: `${base.auth}/recover`,
  reset: `${base.auth}/reset`,
  authorized: `${base.auth}/authorized`,
};

export const user = {
  create: `${base.users}/c`,
  read: {
    email: `${base.users}/r/email`,
    cpf: `${base.users}/r/cpf`,
    id: `${base.users}/r/id`,
    codigo: `${base.users}/r/codigo`,
    all: `${base.users}/r/all`,
  },
  update: {
    email: `${base.users}/u/email`,
    cpf: `${base.users}/u/cpf`,
    telefone: `${base.users}/u/telefone`,
    password: `${base.users}/u/password`,
    active: `${base.users}/u/active`,
  },

  delete: {
    id: `${base.users}/d/id`,
    codigo: `${base.users}/d/codigo`,
  }

};

export const assinantes = {
  create: `${base.assinantes}/c`,
  read: {
    id: `${base.assinantes}/r/id`,
    codigo: `${base.assinantes}/r/codigo`,
    all: `${base.assinantes}/r/all`,
  },
  update: {
    codigo: `${base.assinantes}/u/codigo`,
    nome: `${base.assinantes}/u/nome`,
    email: `${base.assinantes}/u/email`,
    active: `${base.assinantes}/u/active`,
  },
  delete: {
    id: `${base.assinantes}/d/id`,
    codigo: `${base.assinantes}/d/codigo`,
  }
};

export const planos = {
  create: `${base.planos}/c`,
  read: {
    id: `${base.planos}/r/id`,
    codigo: `${base.planos}/r/codigo`,
    all: `${base.planos}/r/all`,
  },
  update: {
    codigo: `${base.planos}/u/codigo`,
    nome: `${base.planos}/u/nome`,
    email: `${base.planos}/u/email`,
    active: `${base.planos}/u/active`,
  },
  delete: {
    id: `${base.planos}/d/id`,
    codigo: `${base.planos}/d/codigo`,
  }
};

export const faturas = {
  create: `${base.faturas}/c`,
  read: {
    id: `${base.faturas}/r/id`,
    codigo: `${base.faturas}/r/codigo`,
    all: `${base.faturas}/r/all`,
  },
  update: {
    codigo: `${base.faturas}/u/codigo`,
    nome: `${base.faturas}/u/nome`,
    email: `${base.faturas}/u/email`,
    active: `${base.faturas}/u/active`,
  },
  delete: {
    id: `${base.faturas}/d/id`,
    codigo: `${base.faturas}/d/codigo`,
  }
};

export const frequencias = {
  create: `${base.frequencias}/c`,
  read: {
    id: `${base.frequencias}/r/id`,
    codigo: `${base.frequencias}/r/codigo`,
    all: `${base.frequencias}/r/all`,
  },
  update: {
    codigo: `${base.frequencias}/u/codigo`,
    nome: `${base.frequencias}/u/nome`,
    email: `${base.frequencias}/u/email`,
    active: `${base.frequencias}/u/active`,
  },
  delete: {
    id: `${base.frequencias}/d/id`,
    codigo: `${base.frequencias}/d/codigo`,
  }
};

export const produtos = {
  create: `${base.produtos}/c`,
  read: {
    id: `${base.produtos}/r/id`,
    codigo: `${base.produtos}/r/codigo`,
    all: `${base.produtos}/r/all`,
  },
  update: {
    codigo: `${base.produtos}/u/codigo`,
    nome: `${base.produtos}/u/nome`,
    email: `${base.produtos}/u/email`,
    active: `${base.produtos}/u/active`,
  },
  delete: {
    id: `${base.produtos}/d/id`,
    codigo: `${base.produtos}/d/codigo`,
  }
};  

export const pagamentos = {
  create: `${base.pagamentos}/c`,
  read: {
    id: `${base.pagamentos}/r/id`,
    codigo: `${base.pagamentos}/r/codigo`,
    all: `${base.pagamentos}/r/all`,
  },
  update: {
    codigo: `${base.pagamentos}/u/codigo`,
    nome: `${base.pagamentos}/u/nome`,
    email: `${base.pagamentos}/u/email`,
    active: `${base.pagamentos}/u/active`,
  },
  delete: {
    id: `${base.pagamentos}/d/id`,
    codigo: `${base.pagamentos}/d/codigo`,
  }
};

export const roles = {
  create: `${base.roles}/c`,
  read: {
    id: `${base.roles}/r/id`,
    codigo: `${base.roles}/r/codigo`,
    all: `${base.roles}/r/all`,
  },
  update: {
    codigo: `${base.roles}/u/codigo`,
    nome: `${base.roles}/u/nome`,
    email: `${base.roles}/u/email`,
    active: `${base.roles}/u/active`,
  },
  delete: {
    id: `${base.roles}/d/id`,
    codigo: `${base.roles}/d/codigo`,
  }
};

export const support = {
  tickets: {
    create: `${base.support.tickets}/c`,
    read: {
      id: `${base.support.tickets}/r/id`,
      codigo: `${base.support.tickets}/r/codigo`,
      all: `${base.support.tickets}/r/all`,
    },
    update: {
      codigo: `${base.support.tickets}/u/codigo`,
      nome: `${base.support.tickets}/u/nome`,
      email: `${base.support.tickets}/u/email`,
      active: `${base.support.tickets}/u/active`,
    },
    delete: {
      id: `${base.support.tickets}/d/id`,
      codigo: `${base.support.tickets}/d/codigo`,
    }
  },
  messages: {
    create: `${base.support.messages}/c`,
    read: {
      id: `${base.support.messages}/r/id`,
      codigo: `${base.support.messages}/r/codigo`,
      all: `${base.support.messages}/r/all`,
    },
    update: {
      codigo: `${base.support.messages}/u/codigo`,
      nome: `${base.support.messages}/u/nome`,
      email: `${base.support.messages}/u/email`,
      active: `${base.support.messages}/u/active`,
    },
    delete: {
      id: `${base.support.messages}/d/id`,
      codigo: `${base.support.messages}/d/codigo`,
    }
  }
};

export const tenants = {
  tenant: {
    create: `${base.tenants.tenant}/c`,
    read: {
      id: `${base.tenants.tenant}/r/id`,
      codigo: `${base.tenants.tenant}/r/codigo`,
      all: `${base.tenants.tenant}/r/all`,
    },
    update: {
      codigo: `${base.tenants.tenant}/u/codigo`,
      nome: `${base.tenants.tenant}/u/nome`,
      email: `${base.tenants.tenant}/u/email`,
      active: `${base.tenants.tenant}/u/active`,
    },
    delete: {
      id: `${base.tenants.tenant}/d/id`,
      codigo: `${base.tenants.tenant}/d/codigo`,
    }
  },
  filiais: {
    create: `${base.tenants.filiais}/c`,
    read: {
      id: `${base.tenants.filiais}/r/id`,
      codigo: `${base.tenants.filiais}/r/codigo`,
      all: `${base.tenants.filiais}/r/all`,
    },
    update: {
      codigo: `${base.tenants.filiais}/u/codigo`,
      nome: `${base.tenants.filiais}/u/nome`,
      email: `${base.tenants.filiais}/u/email`,
      active: `${base.tenants.filiais}/u/active`,
    },
    delete: {
      id: `${base.tenants.filiais}/d/id`,
      codigo: `${base.tenants.filiais}/d/codigo`,
    }
  },
  users: {
    create: `${base.tenants.users}/c`,
    read: {
      id: `${base.tenants.users}/r/id`,
      codigo: `${base.tenants.users}/r/codigo`,
      all: `${base.tenants.users}/r/all`,
    },
    update: {
      codigo: `${base.tenants.users}/u/codigo`,
      nome: `${base.tenants.users}/u/nome`,
      email: `${base.tenants.users}/u/email`,
      active: `${base.tenants.users}/u/active`,
    },
    delete: {
      id: `${base.tenants.users}/d/id`,
      codigo: `${base.tenants.users}/d/codigo`,
    }
  }
};

export const views = {
  planos_produtos: `${base.views}/planos_produtos`,
  tenants_users: `${base.views}/tenants_users`,
  tenants_assinantes: `${base.views}/tenants_assinantes`,
};
