import React, { useState, useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import GlobalContext from '../context/GlobalContext';
import  {  fileIcon } from '../components/IconsUI';
import { useParams } from 'react-router-dom';
import { formatCapitalizeFirst } from '../assets/js/helpers';
import * as end from '../services/endpoints';
import * as api from '../services/requests';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { IMaskInput } from 'react-imask';
import BlobViewer from '../components/BlobViewer';

export default function Tenant() {
  const { navigate, loading, checkLogin, store } = useContext(GlobalContext);
  const { user } = store();
  let { assinantes } = store();

  const { tenant } = useParams();
  const [subscribers, setSubscribers] = useState(assinantes);
  const [files, setFiles] = useState(null);

  const infoTenant =
    subscribers &&
    subscribers.find((assinante) => assinante.TENANT_TENANT === tenant);
  const {
    TENANT_NAME,
    TENANT_EMAIL,
    TENANT_CIDADE_UF,
    TENANT_ENDERECO,
    TENANT_TELEFONE,
    TENANT_CODIGO,
    TENANT_TENANT,
  } = infoTenant;

  const [status, setStatus] = useState({
    name: TENANT_NAME,
    email: TENANT_EMAIL,
    telefone: TENANT_TELEFONE,
    endereco: TENANT_ENDERECO,
    cidadeUF: TENANT_CIDADE_UF,
    tenant_codigo: TENANT_CODIGO,
    tenant: TENANT_TENANT,
  });

  async function getCEP(e) {
    e.preventDefault();
    let sanitizedCEP = e.target.value.replaceAll('-', '');
    const res = await fetch(`https://viacep.com.br/ws/${sanitizedCEP}/json/`);
    const data = await res.json();
    if (data.erro) {
      alert('CEP não encontrado');
    }
    setStatus({
      ...status,
      cidadeUF: `${formatCapitalizeFirst(data.localidade)}-${data.uf}`,
      endereco: `${formatCapitalizeFirst(
        data.logradouro,
      )}, n° S/N, bairro ${formatCapitalizeFirst(data.bairro)}, CEP ${
        data.cep
      }, ${formatCapitalizeFirst(data.localidade)}-${data.uf}`,
    });
  }

  const logged = checkLogin();
  useEffect(() => {
    if (!logged) navigate('/login');
    setSubscribers(assinantes);
    // eslint-disable-next-line
  }, []);

  const editTenantLogo = async (e) => {
    e.preventDefault();
    try {
      await api.postFiles(end.tenants.tenant.update.logo, {
        files: files,
        tenant: status.tenant,
        tenant_codigo: status.tenant_codigo,
        logo_type: files.type,
      });

      setFiles(null);
      document.getElementById('logo_upld').value = '';
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  const editTenantTimbrado = async (e) => {
    e.preventDefault();
    try {
      await api.postFiles(end.tenants.tenant.update.timbrado, {
        files: files,
        tenant: status.tenant,
        tenant_codigo: status.tenant_codigo,
        timbrado_type: files.type,
      });

      setFiles(null);
      document.getElementById('timbrado_upld').value = '';
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  const editTenant = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', status.name);
      formData.append('email', status.email);
      formData.append('telefone', status.telefone);
      formData.append('endereco', status.endereco);
      formData.append('cidade_uf', status.cidadeUF);
      formData.append('tenant', status.tenant);
      formData.append('tenant_codigo', status.tenant_codigo);

      await api.post(end.tenants.tenant.update.all, formData);
      const assinantesResponse = await api.post(
        end.views.tenants_assinantes_users,
      );
      sessionStorage.setItem(
        '@App:assinantes',
        JSON.stringify(assinantesResponse.data),
      );
      const { assinantes } = store();
      setSubscribers(assinantes);
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  if (loading) return <LoadingFull />;

  return (
    <>
      <NavBar logged={logged} />
      <main className='container'>
        <p className='my-2'>Olá, {user}!</p>
        <h4 className='fc-regular mt-3'>{tenant}</h4>

        <h6 className='mt-4'>Dados da Conta</h6>
        <p className='mt-3'>
          <span>Nome</span>: {TENANT_NAME}
          <br />
          <span>Email</span>: {TENANT_EMAIL}
          <br />
          <span>Endereço</span>: {TENANT_ENDERECO}
          <br />
          <span>Cidade-UF</span>: {TENANT_CIDADE_UF}
          <br />
          <span>Telefone</span>: {TENANT_TELEFONE}
        </p>

        <div>
          <span>Logotipo</span>
          <BlobViewer
            blobCode={TENANT_CODIGO}
            blobType='image'
            blobIcon={fileIcon('image')}
            blobTable='TENANTS'
            tenantCode={TENANT_CODIGO}
            tenant={tenant}
            token={store().token}
          />
        </div>

        <p className='mt-3 fw-500'>
          Editar dados da conta
          <br />
          <span className='f08 fc-grey mt-0'>
            Os dados de e-mail, endereço e telefone serão utilizados para todos
            os documentos
          </span>
        </p>

        <Form className='form-base'>
          <div className='d-flex flex-row align-items-center mb-0'>
            <Form.Label className='me-2 f09 my-0'>Logotipo</Form.Label>
            <Form.Control
              type='file'
              size='sm'
              id='logo_upld'
              onChange={(e) => setFiles(e.target.files[0])}
              accept='.png, .jpg, .jpeg'
            />
            <Button
              variant='primary'
              className='ms-1'
              size='sm'
              type='submit'
              onClick={(event) => editTenantLogo(event)}
            >
              Salvar
            </Button>
          </div>
          <p className='mt-0 mb-3 f08 fc-grey'>
            Utilize um arquivo PNG, na proporção 1022px x 171px
          </p>
          <div className='d-flex flex-row align-items-center mb-0'>
            <Form.Label className='me-2 f09 my-0'>Timbrado</Form.Label>
            <Form.Control
              type='file'
              size='sm'
              id='timbrado_upld'
              onChange={(e) => setFiles(e.target.files[0])}
              accept='.doc, .docx'
            />
            <Button
              variant='primary'
              className='ms-1'
              size='sm'
              type='submit'
              onClick={(event) => editTenantTimbrado(event)}
            >
              Salvar
            </Button>
          </div>
          <p className='mt-0 mb-3 f08 fc-grey'>
            Importante: o seu timbrado será utilizado como base, e deve conter
            um único parágrafo, com o seguinte texto:{' '}
            <span className='fc-regular'>{`{{content}}`}</span>
          </p>

          <div className='d-flex flex-column justify-content-between'>
            <FloatingLabel
              label='Nome'
              className='my-1'
            >
              <Form.Control
                type='text'
                id='nome'
                value={status.name}
                onChange={(e) => setStatus({ ...status, name: e.target.value })}
              />
            </FloatingLabel>

            <div className='d-flex flex-row'>
              <FloatingLabel
                label='E-mail'
                className=' my-1 me-1 wp-300 inputme1'
              >
                <Form.Control
                  type='email'
                  id='email'
                  placeholder='email'
                  onChange={(e) =>
                    setStatus({ ...status, email: e.target.value })
                  }
                  value={status.email}
                />
              </FloatingLabel>
              <FloatingLabel
                label='Telefone'
                className='my-1 wp-200 me-1 inputme1'
              >
                <Form.Control
                  type='text'
                  id='telefone'
                  placeholder='telefone'
                  onChange={(e) =>
                    setStatus({ ...status, telefone: e.target.value })
                  }
                  value={status.telefone}
                />
              </FloatingLabel>
              <FloatingLabel
                label='CEP'
                className='my-1'
              >
                <Form.Control
                  type='text'
                  className='wp-120'
                  as={IMaskInput}
                  mask='00000-000'
                  placeholder='CEP'
                  id='cep'
                  value={status.cep}
                  onChange={(e) =>
                    setStatus({ ...status, cep: e.target.value })
                  }
                  onBlur={(e) => {
                    if (e.target.value.length === 9) {
                      getCEP(e);
                    }
                  }}
                />
              </FloatingLabel>
            </div>
            <FloatingLabel
              label='Endereço'
              className='my-1 w100 me-1'
            >
              <Form.Control
                type='text'
                placeholder='Endereço'
                id='endereco'
                className='w100'
                value={status.endereco}
                onChange={(e) =>
                  setStatus({ ...status, endereco: e.target.value })
                }
              />
            </FloatingLabel>

            <FloatingLabel
              label='Cidade-UF'
              className='my-1 w100 me-1'
            >
              <Form.Control
                type='text'
                placeholder='Cidade-UF'
                id='cidadeuf'
                className='w100'
                value={status.cidadeUF}
                onChange={(e) =>
                  setStatus({ ...status, cidadeUF: e.target.value })
                }
              />
            </FloatingLabel>

            <Button
              variant='primary'
              type='submit'
              onClick={(event) => editTenant(event)}
            >
              Salvar
            </Button>
          </div>
        </Form>
      </main>
    </>
  );
}
