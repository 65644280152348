import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import GlobalContext from './GlobalContext';
import * as api from '../services/requests';
import { auth, views } from '../services/endpoints';

function GlobalProvider({ children }) {
  // const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState('global');
  const navigate = useNavigate();

  function store() {
    return {
      token: sessionStorage.getItem('@App:token'),
      email: sessionStorage.getItem('@App:mail'),
      user: sessionStorage.getItem('@App:user'),
      cpf: sessionStorage.getItem('@App:cpf'),
      codigo: sessionStorage.getItem('@App:codigo'),
      telefone: sessionStorage.getItem('@App:telefone'),
      assinantes: JSON.parse(sessionStorage.getItem('@App:assinantes')),
    };
  }

  function checkLogin() {
    const { token, email, user } = store();
    if (!token || !email || !user) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    const token = sessionStorage.getItem('@App:token');
    api.defaults.headers.Authorization = token;
  }, []);

  const removeAspas = (str) => {
    return str.replace(/['"]+/g, '');
  };

  async function login(userData) {
    const response = await api.post(auth.login, userData);
      const { success } = response.data;

    if (!success) {
      return response.data;
    } else {
      const { email, token,  cpf, user, codigo, telefone } = response.data;
      api.defaults.headers.Authorization = token;
      
      sessionStorage.setItem('@App:user', removeAspas(JSON.stringify(user)));
      sessionStorage.setItem('@App:mail', removeAspas(JSON.stringify(email)));
      sessionStorage.setItem('@App:cpf', removeAspas(JSON.stringify(cpf)));
      sessionStorage.setItem('@App:token', removeAspas(JSON.stringify(token)));
      sessionStorage.setItem('@App:codigo', removeAspas(JSON.stringify(codigo)));
      sessionStorage.setItem('@App:telefone', removeAspas(JSON.stringify(telefone)));

      const assinantes  = await api.post(views.tenants_assinantes_users);
      sessionStorage.setItem('@App:assinantes', (JSON.stringify(assinantes.data)));
     
      return response.data;
    }
  }

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate('/');
  };

  const context = {
    login,
    logout,
    store,
    checkLogin,
    navigate,
    loading,
    setLoading,
    produto, setProduto,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalProvider;
