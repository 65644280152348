import React from 'react';
import logo from '../assets/img/logo.png';

function Page500() {
  return (
    <div className='wrappedefpage'>
      <header className='wrapper'>
        <a href='/'>
          <img
            src={logo}
            className='header-logo my-3'
            alt='logo'
          />
        </a>
        <p>Não autorizado</p>
      </header>
    </div>
  );
}

export default Page500;
