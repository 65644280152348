import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../context/Login';
import Logout from '../pages/Logout';
import Register from '../pages/Register';
import Page404 from '../pages/Page404';
import Page500 from '../pages/Page500';
import Recover from '../pages/Recover';
import Reset from '../pages/Reset';
import User from '../pages/User';
import Master from '../pages/Master';
import Juris from '../products/juris/Juris';

import IconsExample from '../pages/IconsExample';
import Tenant from '../pages/Tenant';
import Portal from '../pages/Portal';

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path='/user'
        element={<User />}
      />

      <Route
        path='/master'
        element={<Master />}
      />

      <Route
        exact
        path='/portal'
        element={<Portal />}
      />

      <Route
        exact
        path='/juris'
        element={<Juris />}
      />

      <Route
        path='/juris/:tenant'
        element={<Juris />}
      />
      <Route
        path='/conta/:tenant'
        element={<Tenant />}
      />

      <Route
        exact
        path='/'
        element={<Home />}
      />

      <Route
        exact
        path='/icons'
        element={<IconsExample />}
      />

      <Route
        path='/login'
        element={<Login />}
      />
      <Route
        path='/logout'
        element={<Logout />}
      />
      <Route
        path='/register'
        element={<Register />}
      />
      <Route
        path='/recover'
        element={<Recover />}
      />
      <Route
        path='/reset'
        element={<Reset />}
      />

      <Route
        path='/500'
        element={<Page500 />}
      />
      <Route
        path='*'
        element={<Page404 />}
      />
    </Routes>
  );
}
