import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import '../assets/css/master-sidebar.css';
import '../assets/css/navbar.css';
import GlobalContext from '../context/GlobalContext';
import MasterContext from '../context/MasterContext';
import IconsUI, { ui } from './IconsUI';

export default function SideBar() {
  const { setSection } = useContext(MasterContext);
  const { navigate, logout } = useContext(GlobalContext);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className='container-sidebar'>
      <div className='d-flex flex-row '>
        <Button
          variant='dark'
          size='sm'
          className='mb-1 w100 text-start d-flex flex-row align-items-center justify-content-center'
          onClick={() => {
            const labels = document.querySelectorAll('.sp-label-sidebar');
            labels.forEach((label) => {
              label.classList.toggle('div-hide');
            });
          }}
        >
          <IconsUI
            info={ui.misc.menu}
            clsnm='svg12'
          />
          <span className='ms-1 f09 sp-label-sidebar'>Menu</span>
        </Button>
      </div>
      <div className='mt-2'>
        <div className='d-flex flex-column align-items-start'>
          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('home')}
          >
            <IconsUI
              info={ui.misc.house}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Home</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('driller')}
          >
            <IconsUI
              info={ui.misc.dash}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Driller</span>
          </Button>
          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('users')}
          >
            <IconsUI
              info={ui.user.reg}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Usuários</span>
          </Button>
          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('tenants')}
          >
            <IconsUI
              info={ui.misc.starlight}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Tenants</span>
          </Button>
          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('products')}
          >
            <IconsUI
              info={ui.misc.products}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Produtos</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('subscribers')}
          >
            <IconsUI
              info={ui.money.subscriber}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Assinantes</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('invoices')}
          >
            <IconsUI
              info={ui.money.invoice}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Faturas</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('payments')}
          >
            <IconsUI
              info={ui.money.payment}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Pagamentos</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => setSection('settings')}
          >
            <IconsUI
              info={ui.misc.settings}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Settings</span>
          </Button>

          <Button
            variant='outline-dark'
            size='sm'
            className='mb-1 w100 text-start d-flex flex-row align-items-center '
            onClick={() => handleLogout()}
          >
            <IconsUI
              info={ui.misc.logout}
              clsnm='hov-svg hov-green'
            />
            <span className='ms-1 f09 sp-label-sidebar'>Sair</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
